:root {
  --primary-color: #73999B;
  --hover-color: #828077;
  --text-color: #333;
  --background-color: #e8f0fe;
  --font-size-large: 2.5rem;
  --font-size-medium: 1.8rem;
  --font-size-small: 1.3rem;
  --font-size-xsmall: 1.1rem;
}

/* General Styles */
body {
  font-family: Verdana, sans-serif;
  margin: 0;
  padding: 0 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: 100px;
}

h1, h2, .resume-title, .resume-section-title, .modal-title {
  text-transform: uppercase;
  color: var(--primary-color);
}

h1 {
  font-size: var(--font-size-small); /* Same as menu font size */
}

.resume-section-title {
  font-size: var(--font-size-xsmall);
}

/* Header */
header {
  background-color: var(--primary-color);
  color: #fff;
  padding: 1.5rem 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  left: 0;
  margin-left: calc(-50vw + 50%);
}

header h1 {
  font-size: var(--font-size-large);
  margin: 0;
  letter-spacing: 2px;
}

/* Links */
.name-link,
nav ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.name-link:hover,
nav ul li a:hover {
  color: var(--hover-color);
}

/* Navigation */
nav {
  width: 100%;
  display: flex;
  justify-content: center;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin-top: 10px;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  font-size: var(--font-size-small);
  font-weight: bold;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  position: absolute;
  top: 33px;
  left: 10px;
}

.hamburger .bar {
  width: 28px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
}

/* Main Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Section */
.section {
  margin-bottom: 40px;
}

.section h2 {
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
  font-size: var(--font-size-medium);
}

/* Bio Section */
.bio {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
}

.bio img {
  width: 250px;
  height: 250px;
  border-radius: 5%;
  object-fit: cover;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

.bio h1 {
  font-size: var(--font-size-medium);
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--primary-color);
  margin-bottom: 10px;
  display: inline-block;
  width: calc(100% - 265px);
}

.bio-content p {
  margin-top: 10px;
  white-space: nowrap;
}

.bio-content p:first-of-type {
  clear: none;
  margin-top: 0;
}

.bio-content p:nth-of-type(n+2) {
  clear: both;
  margin-top: 10px;
}

/* Images */
.recipe-image,
.modal-image {
  max-width: 100%;
  width: auto; /* Ensures no fixed width overrides max-width */
  height: auto;
  display: block;
  margin: 10px auto;
  border-radius: 5px;
}

/* Reset Global Image Rules */
img {
  max-width: 100%;
  height: auto;
}

/* Responsive Fix */
@media (max-width: 768px) {
  .recipe-image,
  .modal-image {
    margin: 15px 0;
    max-width: 90%;
    width: auto; /* Override any conflicting widths */
  }

  .container, body {
    overflow-x: hidden; /* Prevent horizontal scrolling issues */
  }
}

/* Modal Close Button */
.modal-close-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.modal-close-button:hover {
  background-color: var(--hover-color);
}


/* Modal Styles */
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80vw; /* Limit modal width for larger screens */
  margin: 0 auto;
  padding: 20px;
  overflow-y: auto;
}

.modal-image-container img {
  max-width: 100%; /* Prevents oversized images */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures full image visibility */
}

@media (max-width: 768px) {
  .modal-content {
    max-width: 90vw; /* Narrower modal for mobile devices */
  }

  .modal-image-container img {
    max-width: 100%; /* Full width on smaller screens */
  }
}

/* Scrollable Table */
.scrollable-table,
.travel-table {
  width: 100%;
  border-collapse: collapse;
}

.scrollable-table th, .scrollable-table td,
.travel-table th, .travel-table td {
  padding: 8px 15px;
  text-align: left;
  border: 1px solid var(--hover-color);
  word-wrap: break-word;
}

.scrollable-table thead {
  background-color: var(--hover-color);
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.scrollable-table tbody {
  display: block;
  height: 400px;
  overflow-y: auto;
}

.scrollable-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.travel-table th {
  background-color: var(--primary-color);
  color: white;
}

.travel-table th:first-child,
.travel-table td:first-child {
  width: min-content;
  white-space: nowrap;
}

.travel-table td {
  background-color: #f9f9f9;
}

.travel-table tr:hover td {
  background-color: #e6f7ff;
}

.highlight-row {
  background-color: #f0f8ff;
  cursor: pointer;
}

/* Map Container */
.map-container {
  width: 100%;
  position: static;
  height: 70vh;
  max-height: 700px;
  z-index: 1;
}

/* Custom Marker Styles */
.custom-marker {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.marker-red {
  background-color: red;
  border: 2px solid white;
}

.marker-grey {
  background-color: grey;
  border: 2px solid white;
}

/* Tooltip Styling */
.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  position: absolute;
  pointer-events: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  nav ul {
    display: none;
  }

  .menu.open ul {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  nav ul li {
    margin: 10px 0;
  }

  header h1 {
    font-size: 2rem;
  }

  .bio img {
    width: 100%;
    margin-bottom: 15px;
  }

  .bio h1 {
    font-size: var(--font-size-medium);
    margin-top: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
  }

  .map-container {
    height: 300px;
  }
}

@media (min-width: 769px) {
  .menu {
    display: flex !important;
    justify-content: center;
    gap: 30px;
  }

  .hamburger {
    display: none;
  }

  nav ul {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  nav ul li {
    margin: 0 30px;
  }

  nav ul li a {
    font-size: 1.5rem;
  }
}

/* Footer */
footer {
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

footer p {
  margin: 0;
}
